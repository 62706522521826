<template>
    <v-container>
      <v-card elevation="4" outlined>
        <v-card-text>
          <v-row>
            <v-card-title>Relatório de Apuração de Icms</v-card-title>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                outlined
                dense
                return-object
                flat
                height="10px"
                label="Empresa"
                item-value="id"
                item-text="fantasyName"
                loader-height
                v-model="report.selectValue"
                :items="enterprise"
              ></v-select>
            </v-col>
            <v-btn
              class="mt-3 mr-4"
              width="200"
              color="success"
              type="submit"
              @click="Generate()"
              :disabled="!valid"
            >
              <v-icon class="mr-2">mdi-file-chart</v-icon> Visualizar</v-btn
            >
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-container>
          <Form :component="this" ref="form" />
        </v-container>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import Form from "../../../components/report/saleConference/Form/index.vue";
  import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
  import { mapGetters } from "vuex";
  
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import lastAutoTable from 'jspdf-autotable';
  import axios from "axios";
  
  export default {
    components: { Form },
  
    data: () => ({
      dialog: Boolean(),
      valid: false,
      // selectValue: Object(),
      userName: String(),
      enterprise: [],
      report: {
        selectValue: Object(),
        date_initial_sales: null,
        date_finish_sales: null,
      },
    }),
  
    methods: {
      async Generate() {
        let payload = {
          enterprise_id: this.report.selectValue.id,
          initial_date: this.report.date_initial_sales,
          final_date: this.report.date_finish_sales,
        }
        let all_report = Object.assign({}, payload);

        
  
        let response = await axios.post("/catalog/getIcmsAssessmentReport", {
          ...all_report,
        });
        // console.log('response', response);

        const report_summary = Object.keys(response.data).map(key => response.data[key]);

        // console.log('report_summary', report_summary);

        this.dialog = !this.dialog;
        this.$refs.form.reset();
        this.$refs.form.validate();
        this.$refs.form.resetValidation();
        let arrayenterprise = [];
        let arrayaddress = [];
        let objeto = Object.assign({}, this.report.selectValue);
        let objetoadress = Object.assign({}, this.report.selectValue.address);
        arrayenterprise.push(objeto);
        arrayaddress.push(objetoadress);

        

        this.createPDF(report_summary ,arrayenterprise, arrayaddress);
      },
  
      createPDF(item, enterprise, address) {
        let data = new Date();

        var doc = new jsPDF("l", "pt");
        let value_account_note_entry_total = 0
        let value_base_calculo_icms_total = Number()
        let value_icms_out_total = Number()
        let value_free_out_total = Number()
        let value_icms_other_out_total = Number()
        for(let i = 0; i<item.length; i++) {
          item[i].value_account_note_entry = parseFloat(item[i].value_account_note_entry.toFixed(2))
          item[i].value_base_calculo_icms = parseFloat(item[i].value_base_calculo_icms.toFixed(2))
          item[i].value_icms_out = parseFloat(item[i].value_icms_out.toFixed(2))
          item[i].value_free_out = parseFloat(item[i].value_free_out.toFixed(2))
          item[i].value_icms_other_out = parseFloat(item[i].value_icms_other_out.toFixed(2))

          value_account_note_entry_total += item[i].value_account_note_entry
          value_base_calculo_icms_total += item[i].value_base_calculo_icms
          value_icms_out_total += item[i].value_icms_out
          value_free_out_total += item[i].value_free_out
          value_icms_other_out_total += item[i].value_icms_other_out
        }
        let totalValues = {
          cfop_description: 'TOTAL', 
          value_account_note_entry: value_account_note_entry_total.toFixed(2),
          value_base_calculo_icms: value_base_calculo_icms_total.toFixed(2),
          value_icms_out: value_icms_out_total.toFixed(2),
          value_free_out: value_free_out_total.toFixed(2),
          value_icms_other_out: value_icms_other_out_total.toFixed(2),
        }
        item.push(totalValues)

        let columns = [
          { title: "Código Fiscal", dataKey: "cfop_code" },
          { title: "Descrição Fiscal", dataKey: "cfop_description" },
          { title: "Valor Contábil", dataKey: "value_account_note_entry" },
          { title: "Base de Cálculo", dataKey: "value_base_calculo_icms" },
          { title: "Imposto Débito", dataKey: "value_icms_out" },
          { title: "Isentas Não Tributadas", dataKey: "value_free_out" },
          { title: "Outros", dataKey: "value_icms_other_out" },
        ];
  
        
        doc.setLineWidth(1);
        doc.line(40, 85, 800, 85);
        doc.line(40, 125, 800, 125);
        doc.setFontSize(7);
        doc.text(`Usuário : ${this.userName}`, 700, 40);
        doc.text(`${data.toLocaleString()}`, 700, 50);
  
        doc.setFontSize(15);
        doc.text("RELATÓRIO DE APURAÇÃO DE ICMS", 270, 70);
  
        doc.setFontSize(7);
        doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
        doc.text(`Razão Social : ${enterprise[0].name}`, 240, 100);
        doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 630, 100);
        doc.text(`Rua : ${address[0].street}`, 40, 115);
        doc.text(`Numero : ${address[0].number}`, 250, 115);
        doc.text(`Cidade : ${address[0].city}`, 340, 115);
        doc.text(`Estado : ${address[0].state}`, 550, 115);
        doc.text(`CEP : ${address[0].cep}`, 700, 115);
  

        doc.setFontSize(13);
        doc.text("Apuração de Icms", 320, 145);
        doc.autoTable(columns, item, {
          columnStyles: {
            //
          },
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          startY: 150,
          theme: "grid",
        });
        // console.log('doc.lastAutoTable.finalY', doc.lastAutoTable.finalY);

  


        
  
        const pages = doc.internal.getNumberOfPages();
        doc.setFontSize(8);
        for (let j = 1; j < pages + 1; j++) {
          doc.setLineWidth(1);
          doc.rect(20, 20, 800, 555);
          doc.setPage(j);
          doc.text(`${j} / ${pages}`, 795, 35);
        }
  
        doc.output("dataurlnewwindow");
      },
  
      async GetAllNoPage() {
        let value = {
          page: null,
          limit: null,
          paginate: false,
        };
        await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
      },
    },
  
    computed: {
      ...mapGetters(["getEnterprises"]),
      ...mapGetters({ userLogin: "auth/userLogin" }),
    },
  
    created() {
      this.GetAllNoPage();
      this.userName = this.userLogin.user.name;
    },
  
    watch: {
      getEnterprises(val) {
        this.enterprise = Object.keys(val).map((key) => val[key]);
        let aux = Object.assign([], this.enterprise[0]);
        this.report.selectValue = aux;
      },
  
    },
  };
  </script>
  